/* LuminousEffect.css */

.luminous {
  position: absolute;
  width: 600px;
  /* z-index: -1; */
  height: 600px;
  background: #490ad37d;
  backdrop-filter: blur(50px);
  filter: blur(100px);
  border-radius: 50%;
  pointer-events: none;
  opacity: 0.4;
  transform: translate(-50%, -50%);
}
