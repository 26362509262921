@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

head {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*,
*:after,
*:before {
  box-sizing: border-box;
}

.titleBorder {
  border: 1px solid black;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.Abel {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

.bGcolor {
  background-color: cadetblue;
}

.activate {
  color: teal;
}
/* Loading CSS */

button:focus {
  outline: 0 !important;
  border: none;
  box-shadow: none;
}

button:active {
  outline: 0;
  box-shadow: none;
}

.footerShadow {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

/* end Loading CSS */

.skills {
  font-family: 'Abel', sans-serif;
}

/* galary */

.gallery-images {
  position: relative;
}

.gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.gallery-image.active {
  opacity: 1;
}

.gallery-image.left {
  transform: translateX(-100%);
}

.gallery-image.right {
  transform: translateX(100%);
}

.skillsBox {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.hero {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.loading-animation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-circle {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 0.1rem solid #ffffff;
  border-top-color: #1a202c;
  animation: loading-circle-spin 7.5s linear infinite;
}

@keyframes loading-circle-spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-top: 1rem;
  font-size: 3rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

/* css */
.sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 9999;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #000;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -300px;
  width: 300px;
  background-color: #fff;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}

.sidebar.show {
  transform: translateX(300px);
}

.sidebar-header {
  height: 75px;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-links {
  height: calc(100% - 80px);
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.sidebar-links a {
  margin: 10px 0;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  color: #000;
  text-decoration: none;
}

.sidebar-links a.active {
  font-weight: bold;
  color: #333;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1000px) {
  .my-fonts {
    font-family: 'Roboto Slab', serif;
    /* font-family: 'Rubik Pixels', cursive; */
    font-size: 10vw;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 420px) {
  .skillsBox {
    width: 10rem !important;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
}
@media only screen and (min-width: 1000px) {
  .my-fonts {
    font-family: 'Roboto Slab', serif;
    /* font-family: 'Rubik Pixels', cursive; */
    font-size: 70px !important;
  }
}

body {
  overflow-x: hidden;
}

.example::-webkit-scrollbar {
  display: none;
}

.example {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 75%; */
}
